import { render, h } from 'vue'

export default function mount (component, { props, element } = {}, slots = []) {
  let el = element || document.createElement('div')

  let vNode = null

  if (slots.length > 0) {
    vNode = h(
      component,
      props,
      slots.map((slot) => {
        if (typeof slot === 'string') { return slot }

        if (Array.isArray(slot)) { return h(...slot) }

        console.error('Invalid type for slots in h, https://vueframework.com/docs/v3/ru/ru/guide/render-function.html#виртуальное-dom-дерево');
        return null
      })
    )
  } else {
    vNode = h(component, props)
  }

  if (!window.vueApp || !window.vueApp._context) {
    console.error('vue app instance is not defined')
  }

  vNode.appContext = window.vueApp._context
  render(vNode, el)
  return vNode
}
