export default {
  beforeMount(el, { value: options, modifiers }, vnode) {
    el.addEventListener('click', () => {
      if (el.classList.contains('open')) {
        el.classList.remove('open');
      } else {
        el.classList.add('open');
      }
    })
  }
}
