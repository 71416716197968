import axios from 'axios'
import qs from 'qs'

export default {
  install (app) {
    axios.defaults.paramsSerializer = (params) => qs.stringify(params, { arrayFormat: 'brackets' })

    const csrfToken = document.querySelector('meta[name=csrf-token]')

    if (csrfToken) {
      axios.defaults.headers.common['X-CSRF-Token'] = csrfToken.content
    }
    axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

    app.config.globalProperties.$axios = axios
  }
}
