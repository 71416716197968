const RULES = [
  // вставляет неразрывный пробел в числах
  (text) => text.replace(/(\d)(\s+)(?=\d)/gm, '$1&nbsp;'),

  // вставляет неразрывный пробел после предлога
  (text) => {
    const prepositions = [
      // RU
      'без', 'безо', 'в', 'во', 'вместо', 'вне', 'для', 'до', 'за', 'из', 'изо', 'из-за', 'из-под',
      'к', 'ко', 'кроме', 'между', 'на', 'над', 'о', 'об', 'обо', 'от', 'ото', 'перед', 'передо',
      'по', 'под', 'подо', 'при', 'про', 'ради', 'с', 'со', 'сквозь', 'среди', 'у', 'через', 'и',

      // EN
      'at', 'on', 'in', 'about', 'above', 'below', 'after', 'before', 'by', 'for', 'from', 'of', 'since', 'to', 'with',

      // Символы
      '№'
    ]

    let result = text

    prepositions.forEach((preposition) => {
      let reg = new RegExp(`(\\s${preposition})(\\s+)`, 'gmi')
      result = result.replace(reg, '$1&nbsp;')
    })

    return result
  }
]

function typograf (text) {
  let result = text
  RULES.forEach((rule) => {
    result = rule.call(this, result)
  })
  return result
}

export default typograf
