import 'viewerjs/dist/viewer.css';
import Viewer from 'viewerjs';

export default {
  beforeMount (el) {
    el.style.setProperty('cursor', 'pointer')

    // eslint-disable-next-line no-unused-vars
    const viewer = new Viewer(el, {
      title: false,
      movable: true,
      transition: false,
      navbar: false,
      toolbar: {
        zoomIn: {
          show: true,
          size: 'large'
        },

        zoomOut: {
          show: true,
          size: 'large'
        },

        rotateLeft: {
          show: true,
          size: 'large'
        },

        rotateRight: {
          show: true,
          size: 'large'
        },

        oneToOne: false,
        reset: false,
        prev: false,
        next: false,
        flipHorizontal: false,
        flipVertical: false
      }
    })
  }
}
