<template>
  <div>
    <slot name="inputs" :setPasswordValue="setPasswordValue" />

    <div class="rules body-sm-txt mn-xs-b">
      <div
        class="rule d-flex acn jfs"
        :class="rule.passed ? '' : 'active'"
        v-for="rule in rules"
        :key="rule._uniqueId"
      >
        <img :src="require('assets/images/icons/check.svg')">
        <div class="text">{{ rule.title }}</div>
      </div>
    </div>

    <slot name="buttons" :isPassed="rulesPassed === 3" />
  </div>
</template>

<script>
  export default {
    data () {
      return {
        passwordValue: '',
        rulesPassed: 0,
        rules: [
          {
            title: this.$t('devise.registrations.set_password.min_symbols_rule'),
            passed: false
          },
          {
            title: this.$t('devise.registrations.set_password.letters_rule'),
            passed: false
          },
          {
            title: this.$t('devise.registrations.set_password.numbers_rule'),
            passed: false
          }
        ]
      }
    },

    mounted () {
      this.setPasswordValue()
    },

    methods: {
      setPasswordValue () {
        this.passwordValue = document.getElementsByName('user[password]')[0].value

        if (this.passwordValue.length >= 10) {
          this.disableRule(0)
        } else {
          this.enableRule(0)
        }

        if (/[A-Z]/.test(this.passwordValue) && /[a-z]/.test(this.passwordValue)) {
          this.disableRule(1)
        } else {
          this.enableRule(1)
        }

        if (/\d/.test(this.passwordValue)) {
          this.disableRule(2)
        } else {
          this.enableRule(2)
        }
      },

      disableRule (n) {
        if (this.rules[n].passed) return

        this.rules[n].passed = true
        this.rulesPassed += 1
      },

      enableRule (n) {
        if (!this.rules[n].passed) return

        this.rules[n].passed = false
        this.rulesPassed -= 1
      }
    }
  }
</script>

<style lang="scss" scoped>
  .rule {
    gap: 10px;
    &.active {
      filter: grayscale(100%);

      .text {
        color: var(--dark-grey);
      }
    }
  }
</style>
