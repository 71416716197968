import some from 'lodash/some'

const EXCLUDE_TAGS = ['a', 'button']

export default {
  beforeMount (el, { value: link }, vnode) {
    let openLinkInNewTab = () => {
      const win = window.open(link, '_blank');
      win.focus();
    }

    let openLink = () => {
      if (vnode.target === '_blank') {
        openLinkInNewTab()
      } else { Turbo.visit(link) }
    }

    let hasActiveElement = (target) => some(
      EXCLUDE_TAGS,
      (tagName) => target.tagName === tagName || target.closest(tagName)
    )

    el.addEventListener('click', (e) => {
      if (hasActiveElement(e.target)) { return }

      if (e.ctrlKey) {
        openLinkInNewTab(e)
      } else {
        openLink(e)
      }
    })

    el.addEventListener('auxclick', (e) => {
      if (hasActiveElement(e.target)) { return }

      openLinkInNewTab(e)
    })
  }
}
