import typograf from '../library/typograf'

export default {
  mounted (el) {
    el.innerHTML = typograf(el.innerText)
  },

  updated (el) {
    el.innerHTML = typograf(el.innerText)
  }
}
