let orderInputs = null

export default {
  beforeMount (el) {
    orderInputs = el.querySelectorAll('input')
    // eslint-disable-next-line no-use-before-define
    syncValues()
    // eslint-disable-next-line no-use-before-define
    window.addEventListener('history-changed', handleHistoryChange)
  },

  unmounted () {
    // eslint-disable-next-line no-use-before-define
    window.removeEventListener('history-changed', handleHistoryChange)
  }
}

const syncValues = () => {
  const url = new URL(window.location.href)
  orderInputs.forEach((input) => {
    let i = input
    i.value = url.searchParams.get(i.name)
  })
}

let previousUrl = ''
const handleHistoryChange = () => {
  if (window.location.href !== previousUrl) {
    previousUrl = window.location.href
    syncValues()
  }
}
