export default {
  template: '<div><slot v-bind="this"/></div>',
  props: {
    initSeconds: { default: 15 }
  },

  data () {
    return {
      seconds: this.initSeconds > 0 ? this.initSeconds : 0
    }
  },

  mounted () {
    let interval = setInterval(() => {
      if (this.seconds == 0) {
        clearInterval(interval);
      } else {
        this.seconds--
      }
    }, 1000)
  }
}
