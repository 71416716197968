<template>
  <app-input-box :errors="cmpErrors" :is-cleaned="false" :helper-text="helperText">
    <input
      type="hidden"
      :value="trueValue"
      :name="name"
      v-if="isChecked"
    >

    <input
      type="hidden"
      :value="falseValue"
      :name="name"
      v-else
    >

    <input-checkbox
      v-bind="$attrs"
      :checked="shouldBeChecked"
      :label="label"
      :tooltip="tooltip"
      @update="update"
    />
  </app-input-box>
</template>

<script>
  import inputMixin from '../../mixins/inputMixin'

  export default {
    mixins: [inputMixin],

    props: {
      checked: { type: Boolean, default: null },
      trueValue: { default: true },
      falseValue: { default: false },
      modelValue: { default: false }
    },

    emits: ['update:modelValue'],

    data () {
      return {
        isChecked: this.checked === null ? this.modelValue === this.trueValue : this.checked
      }
    },

    computed: {
      shouldBeChecked () {
        if (this.localValue instanceof Array) {
          return this.localValue.includes(this.trueValue)
        }
        return this.isChecked
      }
    },

    watch: {
      checked (n) {
        this.isChecked = n
      }
    },

    mounted () {
      if (this.isChecked) {
        this.cmpValue = this.trueValue
      } else {
        this.cmpValue = this.falseValue
      }
    },

    methods: {
      update (checked) {
        this.isChecked = checked
        this.cmpValue = this.isChecked ? this.trueValue : this.falseValue
        this.setValue()
        this.clean()
      },

      clean () {
        this.cmpErrors = []
      },

      setValue () {
        if (this.localValue instanceof Array) {
          let newValue = [...this.localValue]

          if (this.isChecked) {
            newValue.push(this.trueValue)
          } else {
            newValue.splice(newValue.indexOf(this.trueValue), 1)
          }

          this.localValue = newValue
          this.$emit('update:modelValue', newValue)
        } else {
          this.localValue = this.isChecked ? this.trueValue : this.falseValue
          this.$emit('update:modelValue', this.localValue)
        }
      }
    }
  }
</script>
