import get from 'lodash/get'
import clone from 'lodash/clone'
import last from 'lodash/last'
import reduce from 'lodash/reduce'

const UNDEFINED_STATE = 'undefined_state_value'

const dynamicSetState = (state, payload, isError) => {
  const path = clone(payload.path)
  const clonedValue = clone(payload.value)
  const isArray = last(path) === '[]'
  const value = !isArray || isError ? clonedValue : (!!clonedValue ? [clonedValue] : [])

  if (isArray) { path.pop() }

  const lastToken = path.pop()
  const lastObj = reduce(path, (currentState, token) => {
    const obj = get(currentState, token, UNDEFINED_STATE)

    if (obj === UNDEFINED_STATE) {
      currentState[token] = {}
      return currentState[token]
    }

    return obj
  }, state)

  lastObj[lastToken] = value
}

export { dynamicSetState }
