export default function initConfirmation (app) {
  const handleConfirm = (event) => {
    event.stopPropagation();
    event.preventDefault();

    const { target } = event
    app.config.globalProperties.$modal.show('confirmDialog', target)
  }

  const links = document.querySelectorAll('a[data-modal-confirm]')
  links.forEach((link) => { link.onclick = handleConfirm })
}
