import some from 'lodash/some'
import isMobile from 'is-mobile'

const EXCLUDE_TAGS = ['a', 'button']

export default {
  beforeMount (el, { value: display }) {
    if (display === false) { return }

    el.classList.add('tr-accordion')

    let hasActiveElement = (target) => some(
      EXCLUDE_TAGS,
      (tagName) => target.tagName === tagName || target.closest(tagName)
    )

    el.addEventListener('click', (e) => {
      if (hasActiveElement(e.target)) { return }

      const dropEl = el.nextElementSibling

      if (dropEl) {
        dropEl.classList.toggle('hidden')
        dropEl.classList.toggle('open')
        el.classList.toggle('expanded')

        if (isMobile()) {
          dropEl.addEventListener('click', () => {
            dropEl.classList.add('hidden')
            el.classList.remove('expanded')
          })
        }
      }
    })
  }
}
