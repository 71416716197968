import debounce from 'lodash/debounce'

export default {
  beforeMount (el, binding) {
    const input = el.querySelector('input') || el
    let root = document.querySelector(':root')

    input.addEventListener('input', debounce(function (e) {
      root.style.setProperty(binding.value, e.target.value)
    }, 5))
  }
}
