import mount from './runtime-mount'

export default function vueCompileHtml (html, isDocumentFragment = false) {
  let template = `<div>${html}</div>`

  if (isDocumentFragment) {
    let div = document.createElement('div')
    div.append(...html.children)
    template = div.outerHTML
  }

  const vNode = mount({ template })

  if (isDocumentFragment) {
    let fragment = document.createDocumentFragment()
    fragment.appendChild(vNode.el)

    return fragment
  }

  return vNode.el
}

window.vueCompileHtml = vueCompileHtml
