import debounce from 'lodash/debounce';

function submitForm (el, url) {
  const form = el.closest('form') || document.forms[0]

  if (!form) return
  const event = new Event('submit', { bubbles: true, cancelable: true })

  if (url) {
    const defaultFormAction = form.action
    form.action = url
    form.dispatchEvent(event)
    form.action = defaultFormAction
  } else {
    form.dispatchEvent(event)
  }
}

export default {
  beforeMount (el, binding) {
    const { url } = binding.value

    el.addEventListener('input', debounce(() => {
      submitForm(el, url)
    }, 300))

    el.addEventListener('change', debounce(() => {
      submitForm(el, url)
    }, 300))
  }
}
