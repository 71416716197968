<template>
  <label>
    <input
      v-bind="$attrs"
      :name="name"
      :checked="isChecked"
      type="checkbox"
      @change="updateHandler"
    >

    <span class="label">
      <span v-if="typograf" v-typograf>
        {{ label }}
      </span>
      <span v-else v-html="label" />
      <app-tooltip
        class="mn-10-l"
        :text="tooltip"
        v-if="tooltip"
      />
    </span>

    <span class="mark" row="cn">
      <svg
        width="14"
        height="11"
        viewBox="0 0 14 11"
        fill="none"
      >
        <path d="M1 5L5.42119 9L13 1" stroke="var(--grey)" stroke-width="2" />
      </svg>
    </span>
  </label>
</template>

<script>
  export default {
    props: {
      name: { type: String, default: '' },
      checked: { type: Boolean, default: null },
      label: { type: String, default: null },
      tooltip: { type: String, default: null },
      typograf: { type: Boolean, default: true }
    },

    emits: ['update'],

    data () {
      return {
        isChecked: this.checked
      }
    },

    watch: {
      checked (n) {
        this.isChecked = n
      }
    },

    methods: {
      updateHandler (e) {
        this.isChecked = e.target.checked
        this.$emit('update', e.target.checked)
      }
    }
  }
</script>

<style lang="scss" scoped>

  $label-height: 24px;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .mark {
      background-color: var(--ui-color);
      border: 1px solid transparent;

      svg path { stroke: var(--on-ui-color) }
    }

    &:checked ~ .mark::after {
      display: flex;
    }

    &:disabled ~ .mark {
      cursor: default;
      background-color: var(--light-grey);
      border: 1px solid transparent;
    }

    &:disabled ~ .label {
      cursor: default;
      color: var(--dark-grey);
    }

    &:checked:disabled ~ .mark {
      cursor: default;
      background-color: var(--light-grey);
      border: 1px solid transparent;
      color: var(--dark-grey);
      svg path { stroke: var(--grey) }
    }

    &:checked:disabled ~ .label {
      cursor: default;
      color: var(--dark-grey);
    }
  }

  .label {
    display: flex;
    margin-left: 10px;
  }

  .mark {
    position: absolute;
    top: 0;
    left: 0;
    height: $label-height;
    width: $label-height;
    border-radius: 6px;
    border: 1px solid var(--grey);
    box-sizing: border-box;

    &:hover { background-color: var(--light-grey) }

    &::after {
      position: absolute;
      display: none;
      color: var(--white);
    }

    svg path {
      stroke: transparent;
    }
  }

  label {
    position: relative;
    display: flex;
    align-items: center;
    min-height: $label-height;
    line-height: 20px;
    padding-left: $label-height;
    cursor: pointer;
    user-select: none;

    @include mobile {
      font-size: 16px;
    }
  }
</style>
