<template>
  <div class="app-code-box" :class="{ 'loading-overlay transparent': disabled }">
    <div
      class="digits"
      row=""
      ref="digits"
    >
      <span
        class="digit"
        :class="{empty: !getSymbolByIndex(n-1)}"
        v-for="n in 6"
        :key="n"
      >
        {{ getSymbolByIndex(n-1) }}
      </span>
    </div>

    <input
      :name="name"
      inputmode="numeric"
      type="text"
      maxlength="6"
      :disabled="disabled"
      autofocus
      autocomplete="off"
      data-maska="######"
      ref="code"
      v-model="code"
      v-maska
      @blur="focusByBlur"
      @wheel.prevent
    >
  </div>
</template>

<script>
  import isMobile from 'is-mobile'

  export default {
    props: {
      name: { type: String, default: '' },
      modelValue: { type: [Number, String], default: '' },
      formSelector: { type: String, default: null },
      disabled: { type: Boolean, default: false }
    },

    emits: ['update:modelValue'],

    data () {
      return {
        code: this.modelValue
      }
    },

    watch: {
      modelValue (newValue) {
        this.code = newValue
      },

      code (newValue) {
        if (this.formSelector) {
          this.submitForm(newValue)
        } else {
          this.$emit('update:modelValue', newValue)
        }

        this.paintInput()
      }
    },

    mounted () {
      this.focusCode()
      this.paintInput()
    },

    methods: {
      getSymbolByIndex (index) {
        return this.code[index] || String.fromCharCode(9679)
      },

      submitForm (code) {
        if (code.length < 6) { return }

        document.querySelector(this.formSelector).submit();
      },

      focusCode () {
        document.activeElement.blur()
        this.$refs?.code?.focus()
      },

      focusByBlur () {
        if (isMobile()) { return }
        this.focusCode()
      },

      paintInput () {
        if (this.code.length > 6) { return }

        const digitsElems = this.$refs.digits.children

        for (let i = 0; i < 6; i++) {
          digitsElems[i].classList.remove('focused')

          if (i < this.code.length) {
            digitsElems[i].classList.add('filled')
          } else {
            digitsElems[i].classList.remove('filled')
          }
        }

        digitsElems[this.code.length]?.classList?.add('focused')
      }
    }
  }
</script>

<style lang="scss" scoped>
  .app-code-box {
    position: relative;

    input {
      position: absolute;
      top: 0;
      width: 100%;
      height: 40px;
      opacity: 0;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      &[type=number] {
        -moz-appearance: textfield;
      }
    }

    .digit {
      width: 60px;
      height: 50px;
      font-size: 50px;
      line-height: 100%;
      text-align: center;
      font-feature-settings: 'tnum' on, 'lnum' on;
      font-weight: bold;
      color: var(--grey);

      &.filled {
        color: var(--ui-color);
      }

      &.focused {
        animation: bgColor 1s infinite linear;
      }

      @include mobile {
        width: 38px;
      }

      &:not(last-child) {
        margin-right: 3px;
      }
    }
  }

  @keyframes bgColor {
    0% { color: var(--grey); }
    24% { color: var(--grey); }
    25% { color: var(--ui-color); }
    75% { color: var(--ui-color); }
    76% { color: var(--grey); }
    100% { color: var(--grey); }
  }

</style>
