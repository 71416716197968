// https://github.com/CodeSeven/toastr
import toastr from 'toastr'
import each from 'lodash/each'
import isMobile from 'is-mobile'

toastr.options = {
  positionClass: `${isMobile() ? 'toast-bottom-full-width' : 'toast-bottom-right'}`,
  closeButton: true,
  tapToDismiss: false
}

if (window.gon.env === 'test') {
  Object.assign(
    toastr.options, {
      tapToDismiss: false,
      showDuration: 0,
      hideDuration: 0,
      timeOut: 200000,
      showEasing: false,
      hideEasing: false
    }
  )
}

window.toastr = toastr
/**
 * Всплывающий текст
 * @hidden
 */
export default {
  props: ['messages'],

  created () {
    each(this.messages, (message) => {
      if (message[1] === 'success') {
        this.$successToastr()
      } else {
        const decorator = toastr[message[0]]

        if (typeof decorator === 'function') {
          decorator(message[1])
        }
      }
    })
  },

  render () {}
}
