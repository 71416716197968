import find from 'lodash/find'

export default {
  mounted (el, binding) {
    const checkAllElem = el
    const checkboxes = document.getElementsByName(binding.value)

    const checkState = () => {
      checkAllElem.checked = !find(checkboxes, (item) => !item.checked)
    }

    checkState()
    checkboxes.forEach((item) => { item.addEventListener('click', checkState) })
    checkAllElem.addEventListener('click', () => {
      checkboxes.forEach((item) => {
        let checkbox = item
        checkbox.checked = checkAllElem.checked
      })
    })
  }
}
