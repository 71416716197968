export default {
  install (app) {
    const locale = document.documentElement.lang

    app.config.globalProperties.$currencyLocale = (value, currencyCode) => {
      const localeValue = new Intl.NumberFormat(locale, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 8,
        trailingZeroDisplay: 'stripIfInteger',
        style: 'currency',
        currency: currencyCode
      }).format(value)

      return localeValue
    }
  }
}
