<template>
  <div class="sms-clarification">
    <i class="material-icons">info</i>
    <p class="body-txt">
      {{ clarification }}
    </p>
  </div>
</template>

<script>
  export default {
    props: {
      clarification: { type: String, required: true }
    }
  }
</script>

<style lang="scss" scoped>
  .sms-clarification {
    max-width: 540px;
    box-sizing: border-box;
    display: flex;
    padding: 20px;
    background-color: var(--light-grey);
    border-radius: 10px;

    i {
      color: var(--black);
      font-size: 18px;
      margin: 3px 20px 0 0;

      @include mobile {
        margin: 3px 16px 0 0;
      }
    }
  }
</style>
