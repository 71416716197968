export default {
  beforeMount (el) {
    el.addEventListener('change', (e) => {
      let form = e.target.closest('form')

      const event = new Event('submit', { bubbles: true, cancelable: true });
      form.dispatchEvent(event);
    })
  }
}
