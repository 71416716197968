export default {
  mounted (el, binding) {
    setTimeout(() => {
      const elem = el.querySelector(binding.value.accentElem)
      if (elem) {
        elem.scrollIntoView(binding.value.alignRules)
      }
    }, 1);
  }
}
