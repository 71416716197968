import isMobile from 'is-mobile'

export default {
  beforeMount (el) {
    if (!isMobile() && (!window.navigator.userAgent.indexOf('Mac') !== -1)) {
      if (navigator.userAgent.indexOf('Opera') !== -1 || navigator.userAgent.indexOf('OPR') !== -1) {
        executeResizeObserver(el, 'opera')
      } else if (navigator.userAgent.indexOf('Chrome') !== -1) {
        executeResizeObserver(el, 'chrome')
      } else if (navigator.userAgent.indexOf('Firefox') !== -1) {
        executeResizeObserver(el, 'firefox')
      }
    }
  }
}

function executeResizeObserver (el, browser) {
  const body = document.querySelector('body')

  const ro = new ResizeObserver(() => {
    if (window.innerWidth <= document.body.clientWidth) {
      body.classList.add(`${browser}-scroll-mock`)
    } else {
      body.classList.remove(`${browser}-scroll-mock`)
    }
  })

  ro.observe(el)
}
