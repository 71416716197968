import vueCompileHtml from 'system/vue-compile-html'

export default {
  after () {
    this.targetElements.forEach((e) => {
      e.parentElement?.insertBefore(vueCompileHtml(this.templateContent, true), e.nextSibling)
    })
  },

  append () {
    this.removeDuplicateTargetChildren()
    this.targetElements.forEach((e) => e.append(vueCompileHtml(this.templateContent, true)))
  },

  before () {
    this.targetElements.forEach((e) => e.parentElement?.insertBefore(vueCompileHtml(this.templateContent, true), e))
  },

  prepend () {
    this.removeDuplicateTargetChildren()
    this.targetElements.forEach((e) => e.prepend(vueCompileHtml(this.templateContent, true)))
  },

  remove () {
    this.targetElements.forEach((e) => e.remove())
  },

  replace () {
    this.targetElements.forEach((e) => e.replaceWith(vueCompileHtml(this.templateContent, true)))
  },

  update () {
    this.targetElements.forEach((e) => {
      e.innerHTML = ''
      e.append(vueCompileHtml(this.templateContent, true))
    })
  }
}
