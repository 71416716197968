export default {
  beforeMount (el, { value: link, modifiers }) {
    let openLink = () => {
      if (link === '') return
      Turbo.visit(link)
    }

    let openLinkInNewTab = () => {
      let win = window.open(link, '_blank');
      win.focus();
    }

    el.addEventListener('click', (e) => {
      if (e.target.closest('a')) { return }
      if (e.ctrlKey || modifiers.external) {
        openLinkInNewTab(e)
      } else {
        openLink(e)
      }
    })

    el.addEventListener('auxclick', (e) => {
      openLinkInNewTab(e)
    })
  }
}
