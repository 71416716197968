const CURRENCY = 'RUB'

export default {
  beforeMount (el, binding) {
    window.dataLayer = window.dataLayer || []
    const { data } = binding.value
    const { type } = binding.value

    switch (binding.value.action) {
      case 'add':
        addClickListener(el, data, type)
        break
      case 'purchase':
        purchaseProduct(data)
        break

      default:
        console.warn('Action is not defined');
        break
    }
  }
}

function addClickListener (el, data, type) {
  const { products } = data
  let product = []

  el.addEventListener('click', (e) => {
    e.target.disabled = true
    const form = e.target.closest('form')

    if (!form) {
      console.error('Form for submit is not found');
      return
    }

    if (type === 'options') {
      product.push(findSelectedProduct(form, products))
    } else {
      product = products
    }

    addProduct(product)
    form.submit()
  })
}

function findSelectedProduct (form, products) {
  const formData = new FormData(form)
  const productId = formData.get('payment[service_tariff]')

  return products.find((product) => +product.id === +productId)
}

function addProduct (product) {
  window.dataLayer.push({
    ecommerce: {
      currencyCode: CURRENCY,
      add: {
        products: product
      }
    }
  })
}

function purchaseProduct (data) {
  const { products } = data
  const { actionFieldId } = data

  window.dataLayer.push({
    ecommerce: {
      currencyCode: CURRENCY,
      purchase: {
        actionField: { id: actionFieldId },
        products
      }
    }
  })
}
