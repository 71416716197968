import { createStore } from 'vuex'
import { dynamicSetState } from '../store/utils'

export default {
  install (app) {
    const store = createStore({
      state: {
        errors: {}
      },

      getters: {
        newMessage: (state) => (roomId) => {
          if (state.chatRooms) {
            return state.chatRooms[`room${roomId}`];
          }
        }
      },

      mutations: {
        SET_STATE (state, payload) {
          dynamicSetState(state, payload)
        },
        SET_ERRORS (state, payload) {
          dynamicSetState(state.errors, payload, true)
        }
      },

      actions: {}
    })

    app.config.globalProperties.$store = store
  }
}
