import { hexToHSL, HSLToHex } from '../library/colorConverters';

export default {
  beforeMount (el, binding) {
    transformColor(el, binding)
  },

  updated (el, binding) {
    transformColor(el, binding)
  }
}

function transformColor(el, binding) {
  const input = el.querySelector('input') || el
  input.value = getLightenHex(el, binding)
  generateInputEvent(input)
}

function getLightenHex (el, binding) {
  if (!binding.value.hex) { return }

  if (binding.value.power) {
    return colorLighten(binding.value.hex, +binding.value.power)
  } else if (binding.value.lightness) {
    return colorSetLightness(binding.value.hex, +binding.value.lightness)
  }
}

function colorLighten (hex, lightenBy) {
  if (lightenBy) {
    const hsl = hexToHSL(hex)
    hsl.array.l += lightenBy

    return HSLToHex(hsl.array.h, hsl.array.s, hsl.array.l)
  }
}

function colorSetLightness (hex, lightness) {
  if (lightness) {
    const hsl = hexToHSL(hex)
    hsl.array.l = lightness

    return HSLToHex(hsl.array.h, hsl.array.s, hsl.array.l)
  }
}

function generateInputEvent(input) {
  const event = new Event('input');
  input.dispatchEvent(event);
}
