export default {
  beforeMount (el) {
    let touchstartX = 0
    let touchendX = 0

    const slider = el

    slider.addEventListener('touchstart', (event) => {
      touchstartX = event.changedTouches[0].screenX
    })

    slider.addEventListener('touchend', (event) => {
      touchendX = event.changedTouches[0].screenX
      handleGesture(touchstartX, touchendX, el)
    })
  }
}

function handleGesture (touchstartX, touchendX, el) {
  if (touchstartX > touchendX && touchstartX - touchendX > 100) {
    el.dispatchEvent(new Event('swipe-left'));
  }

  if (touchstartX < touchendX && touchendX - touchstartX > 100) {
    el.dispatchEvent(new Event('swipe-right'));
  }
}
