<template>
  <app-input-box
    :errors="cmpErrors"
    :clean="clean"
    :is-cleared="isCleared"
    :helper-text="helperText"
  >
    <input type="hidden" :name="name" v-model="phoneValue">

    <vue-tel-input
      :auto-default-country="true"
      :dynamic-placeholder="true"
      mode="international"
      :dropdown-options="dropdownOptions"
      :input-options="inputOptions"
      :preferred-countries="preferredCountries"
      :class="{filled: cmpValue, 'without-label': !label}"
      v-model="cmpValue"
      @focus="focused"
      @on-input="update"
      @click="cmpErrors = []"
    />
  </app-input-box>
</template>

<script>
  import { VueTelInput } from 'vue-tel-input'; // https://github.com/iamstevendao/vue-tel-input
  import 'vue-tel-input/dist/vue-tel-input.css';
  import inputMixin from '../../mixins/inputMixin'

  export default {
    components: {
      'vue-tel-input': VueTelInput
    },

    mixins: [inputMixin],

    emits: ['update:modelValue'],

    data () {
      return {
        phoneValue: '',

        dropdownOptions: {
          showFlags: true,
          showSearchBox: false
        },

        inputOptions: {
          showDialCode: true,
          type: 'tel'
        },

        preferredCountries: ['ru', 'by', 'kz', 'in']
      }
    },

    watch: {
      modelValue (n) {
        if (n !== this.cmpValue) this.cmpValue = n
      }
    },

    mounted () {
      const phone = this.modelValue.replace('+', '')
      this.cmpValue = `+${phone}`
    },

    methods: {
      update (value, object) {
        if (object.formatted) {
          this.phoneValue = object.formatted
        } else if (/^[0-9]*$/.test(object.number)) {
          this.phoneValue = object.number
        } else if (!object.number) {
          this.phoneValue = null
        }

        this.$emit('update:modelValue', this.phoneValue)
      },

      clean () {
        this.cmpValue = null
        this.cmpErrors = []
        this.update()
      },

      focused () {
        this.cmpErrors = []
      }
    }
  }
</script>

<style lang="scss" scoped>
  .input-box {
    &.with-error {
      :deep(.vue-tel-input) {
        border-color: var(--red);
      }
    }
  }

  :deep(.vue-tel-input) {
    height: 62px !important;
    min-height: 62px !important;
    border: 1px solid var(--grey);
    border-radius: 10px;
    font-family: 'TTNormsPro' !important;

    @include mobile {
      height: 60px !important;
      min-height: 60px !important;
    }

    &:hover {
      border: 1px solid var(--dark-grey) !important;
    }

    &:focus-within {
      box-shadow: 0px 0px 0px 3px var(--grey) !important;
      border: 1px solid var(--grey) !important;
    }

    .vti__dropdown {
      border-radius: 10px 0 0 10px;
    }

    .vti__input {
      font-size: 20px;
      border-radius: 0 10px 10px 0;

      @include mobile {
        font-size: 18px;
      }
    }

    .vti__dropdown-list {
      max-width: 300px !important;
      min-width: 300px !important;
      top: calc(100% + 10px) !important;
      box-shadow: unset;
      border-radius: 10px;
      border: 1px solid var(--grey);
      overflow-x: hidden;

      .vti__dropdown-item {
        border-radius: 0px !important;
        font-size: 13px !important;
        color: var(--dark-grey) !important;
        font-family: 'TTNormsPro' !important;

        &:hover {
          color: var(--black) !important;
        }

        &.selected {
          background-color: var(--light-grey) !important;
          color: var(--black) !important;
          font-weight: 400 !important;
        }
      }
    }
  }
</style>
