import { I18n } from 'i18n-js'

const i18n = new I18n()

i18n.store(window.I18n.translations)
i18n.defaultLocale = window.default_locale
i18n.locale = document.documentElement.lang

/* eslint-disable */
i18n.pluralization.registry.ru = (_i18n, count) => {
  const key =
    count % 10 === 1 && count % 100 !== 11 ? 'one'
      : [2, 3, 4].indexOf(count % 10) >= 0 &&
      [12, 13, 14].indexOf(count % 100) < 0
      ? "few"
      : count % 10 === 0 ||
      [5, 6, 7, 8, 9].indexOf(count % 10) >= 0 ||
      [11, 12, 13, 14].indexOf(count % 100) >= 0
        ? "many"
        : "other";
  return [key];
};
/* eslint-enable */

window.I18n = i18n
