export default {
  beforeMount (el, { value }, vnode) {
    if (!value) return

    const input = el.querySelector('input') || el

    const button = document.createElement('button')

    button.classList.add('btn-additional', 'password')
    button.setAttribute('type', 'button')

    const i = document.createElement('i')
    i.classList.add('material-icons', 'visible')
    i.innerHTML = 'visibility'

    button.appendChild(i)
    el.appendChild(button)

    button.addEventListener('click', () => {
      if (i.classList.contains('visible')) {
        input.value = input.value // need for work in chrome
        input.setAttribute('type', 'text')
        i.classList.replace('visible', 'invisible')
        i.innerHTML = 'visibility_off'
      } else {
        input.setAttribute('type', 'password')
        i.classList.replace('invisible', 'visible')
        i.innerHTML = 'visibility'
      }
    })
  }
}
