import { hexToHSL, HSLToHex } from '../library/colorConverters';

export default {
  beforeMount (el, binding) {
    // eslint-disable-next-line no-use-before-define
    adaptateColors(el, binding)
  },

  updated (el, binding) {
    // eslint-disable-next-line no-use-before-define
    adaptateColors(el, binding)
  }
}

const adaptateColors = (el, binding) => {
  // eslint-disable-next-line no-use-before-define
  const hslColors = convertColorsToHSL(binding.value.colors)
  const input = el.querySelector('input') || el
  // eslint-disable-next-line no-use-before-define
  input.value = JSON.stringify(alignLightnessByPprimary(binding.value.target, hslColors))
  // eslint-disable-next-line no-use-before-define
  generateInputEvent(input)
}

const convertColorsToHSL = (hexColors) => {
  const colorsHSL = []
  hexColors.forEach((color) => { colorsHSL.push(hexToHSL(color)) })

  return colorsHSL
}

const alignLightnessByPprimary = (hexTarget, hslColorsArray) => {
  const hslColors = hslColorsArray
  // eslint-disable-next-line no-use-before-define
  let primaryLightnessHSL = getColorLightnessHSL(hexTarget)
  if (primaryLightnessHSL < 45) { primaryLightnessHSL = 45 }
  if (primaryLightnessHSL > 65) { primaryLightnessHSL = 65 }

  const colorsForAlignHEX = []

  hslColors.forEach((color) => {
    // eslint-disable-next-line no-param-reassign
    color.array.l = primaryLightnessHSL
    colorsForAlignHEX.push(HSLToHex(color.array.h, color.array.s, color.array.l))
  })

  return colorsForAlignHEX
}

const getColorLightnessHSL = (colorHEX) => {
  if (!colorHEX) { return }

  const colorHSL = hexToHSL(colorHEX)

  return colorHSL.array.l
}

const generateInputEvent = (input) => {
  const event = new Event('input');
  input.dispatchEvent(event);
}
