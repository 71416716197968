const callSuccessToastr = function () {
  const toastr = document.createElement('div')
  toastr.innerHTML = `
    <div class="success-toastr">
      <div class="success-toastr__icon" />
    </div>
  `

  document.body.appendChild(toastr)
  setTimeout(() => { toastr.remove() }, 1700)
}

const callVisitWithSuccess = (path) => {
  document.addEventListener('turbo:load', callSuccessToastr, { once: true })
  Turbo.visit(path)
}

export default {
  install (app) {
    app.config.globalProperties.$successToastr = callSuccessToastr
    app.config.globalProperties.$visitWithSuccess = callVisitWithSuccess
  }
}

window.callSuccessToastr = callSuccessToastr
