const originError = console.error
window.errors = []

console.error = (args) => {
  window.errors.push(args)
  if (window.errors.length > 100) {
    window.errors.shift(-1)
  }
  return originError(args)
}
