export default {
  install (app) {
    // eslint-disable-next-line no-param-reassign
    app.config.globalProperties.$locale = (path, args) => {
      return path[0] === '.' ? I18n.t(`vue${path}`, args) : I18n.t(`vue.${path}`, args)
    }
    // eslint-disable-next-line no-param-reassign
    app.config.globalProperties.$t = (path, args) => I18n.t(path, args)
  }
}
