function formatValue (el, value) {
  const elem = el
  const locale = document.documentElement.lang

  let formatter = new Intl.NumberFormat(locale, {
    style: 'percent',
    maximumFractionDigits: 2
  })

  elem.innerHTML = value ? formatter.format(value / 100) : 0
}

export default {
  beforeMount (el, { value }) {
    formatValue(el, value)
  },

  updated (el, { value }) {
    formatValue(el, value)
  }
}
